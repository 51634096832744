import { useContext } from 'react';
import { PaymentCuotaContext } from '../context/cuota/PaymentCuotaContext';

export const usePaymentCuota = () => {

    const { payment: profesional, updatePayment: updatePayment } = useContext( PaymentCuotaContext );    

    return {
        pac : profesional,        
        updatePayment: updatePayment
    }
}