import { useReducer } from "react";
import { PaymentReducer } from "./PaymentReducer";
import { PaymentContext } from "./PaymentContext";
import { IPayment } from "../../api/api-types/IPayment";

const INITIAL_STATE: IPayment = {
  
};

interface props {
  children: JSX.Element | JSX.Element[];
}

export const PaymentProvider = ({ children }: props) => {
  const [payment, dispatch] = useReducer(PaymentReducer, INITIAL_STATE);

  const updatePayment = (payment: IPayment) => {
    dispatch({ type: "updatePayment", payload: payment });
  };

  return (
    <PaymentContext.Provider
      value={{
        payment: payment,
        updatePayment: updatePayment,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
