import {
  Alert,
  Box,
  Card,
  CardContent,
  Skeleton,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from "react";
import { PacienteApi } from "../../api/pacienteApi";
import { usePayment } from "../../hooks/usePayment";
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

interface PacienteCardProps {
  patientId: string;
}

export const PacienteCard: React.FC<PacienteCardProps> = ({ patientId }) => {
  const [loading, setLoading] = useState(true);
  const { pac: pay, updatePayment } = usePayment();

  const fetchPacienteData = async () => {
    if (patientId) {
      const api = new PacienteApi();
      try {
        const response = await api.getPacienteByKey(patientId);        
        if (response.data?.error) {
          updatePayment({ error: response.data.error });
        } else {
          response.data!.lastCita!.idConsultorioNombre = patientId;
          updatePayment(response.data ?? {});
        }
      } catch (error: any) {
        if (error.response.data) {
          updatePayment({ error: error.response.data });
        } else if (error.status == 404) {
          updatePayment({ error: 'No se pudo encontrar la cita especificada. Revice que el link que se le envió este correcto.' });
        } else {
          updatePayment({ error: error.message });
        }
      }
    } else {
      updatePayment({
        paciente: {
          nombreApellidos: "",
          telMovil: "",
          tipoDocDescripcion: "",
          nroDoc: "",
          email: "",
          idEstadoDescripcion: "",
          fecAlta: "2022-01-01T00:00:00.000",
        },
        lastCita: {
          idPersonaNombre: "",
          fecDesde: "2022-01-01T08:20:00.000",
          idEstadoDescripcion: "",
          bono: 0,
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPacienteData();
  }, [patientId]);

  return (
    <>
      {pay.error && <Alert severity="error">{pay.error}</Alert>}
      {!pay.error && (
        <>
          <Card sx={{ marginTop: 3 }}>
            <Box sx={{ backgroundColor: '#E3F2FD', padding: 1 }}>
              <Typography variant="h6" sx={{ color: '#1976D2', fontSize: '1rem' }}>
                PACIENTE
              </Typography>
            </Box>
            <CardContent>
              <Grid container spacing={0.75}>
                <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                  <Box display="flex" alignItems="center">
                    <PersonIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1" style={{ textTransform: "uppercase" }}>
                        {pay.paciente?.nombreApellidos || ""}
                      </Typography>}
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12 }} >
                  <Box display="flex" alignItems="center">
                    <BadgeIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1">
                        {pay.paciente?.tipoDocDescripcion || ""}:{pay.paciente?.nroDoc || ""}
                      </Typography>
                    }
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12 }} >
                  <Box display="flex" alignItems="center">
                    <PhoneIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1">
                        {pay.paciente?.telMovil || ""}
                      </Typography>
                    }
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                  <Box display="flex" alignItems="center">
                    <EmailIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1">
                        {pay.paciente?.email || ""}
                      </Typography>
                    }
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginTop: 3 }}>
            <Box sx={{ backgroundColor: '#E3F2FD', padding: 1 }}>
              <Typography variant="h6" sx={{ color: '#1976D2', fontSize: '1rem' }}>
                SESSION
              </Typography>
            </Box>
            <CardContent>
              <Grid container spacing={0.75}>
                <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                  <Box display="flex" alignItems="center">
                    <MedicalServicesIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1" style={{ textTransform: "uppercase" }}>
                        {pay.lastCita?.idPersonaNombre || ""}
                      </Typography>}
                  </Box>
                </Grid>
                <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                  <Box display="flex" alignItems="center">
                    <CalendarTodayIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1">
                        {new Date(pay.lastCita?.fecDesde ?? "").toLocaleDateString()}
                      </Typography>}
                  </Box>
                </Grid>
                <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                  <Box display="flex" alignItems="center">
                    <AccessTimeIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1">
                        {new Date(pay.lastCita?.fecDesde ?? "").toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </Typography>}
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                  <Box display="flex" alignItems="center">
                    <AssignmentTurnedInIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1">
                        {pay.lastCita?.idEstadoDescripcion || ""}
                      </Typography>}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      )
      }
    </>
  );
};
