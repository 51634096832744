import { IPaymentCuota } from "../../api/api-types/IPaymentCuota";
import React from "react";

interface PaymentCuotaContextType {
    payment: IPaymentCuota;
    updatePayment: (payment: IPaymentCuota) => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentCuotaContext = React.createContext<PaymentCuotaContextType>({
    payment: {},
    updatePayment: (payment: IPaymentCuota) => { },
    isLoading: true,
    setIsLoading: () => { },
});