import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

const TransactionListSkeleton: React.FC = () => {
  // Create 5 skeleton items to match the example
  const skeletonItems = [1];
  
  // Create 4 date groups
  const dateGroups = [1];

  return (
    <Stack spacing={3}>
      {dateGroups.map((group) => (
        <Box key={group}>
          {/* Date header skeleton */}
          <Skeleton
            animation="wave"
            height={24}
            width={200}
            sx={{ mb: 2 }}
          />

          {/* Transaction items for this date group */}
          <Stack spacing={2}>
            {skeletonItems.slice(0, group === 3 ? 2 : 1).map((item) => (
              <Box
                key={item}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  py: 1,
                }}
              >
                {/* Avatar skeleton */}
                <Box sx={{ position: 'relative' }}>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}                    
                  />
                  {/* Checkmark skeleton */}
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={16}
                    height={16}
                    sx={{
                      position: 'absolute',
                      bottom: -4,
                      right: -4,                      
                    }}
                  />
                </Box>

                {/* Name and time container */}
                <Box sx={{ flex: 1 }}>
                  <Skeleton
                    animation="wave"
                    height={24}
                    width="80%"                    
                  />
                  <Skeleton
                    animation="wave"
                    height={20}
                    width={60}                    
                  />
                </Box>

                {/* Amount container */}
                <Box sx={{ textAlign: 'right' }}>
                  <Skeleton
                    animation="wave"
                    height={24}
                    width={80}                    
                  />
                  <Skeleton
                    animation="wave"
                    height={20}
                    width={80}                    
                  />
                </Box>
              </Box>
            ))}
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};

export default TransactionListSkeleton;