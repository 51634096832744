import {
  Alert,
  CssBaseline,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import "./App.css";
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import { PaymentProvider } from "./context/bono/PaymentProvider";

import { CopyrightBand } from "./components/shared/CopyrightBand";
import MainToolBar from "./components/shared/MainToolBar";
import queryString from "query-string";
import { PacienteCardv1 } from "./components/bonov1/PacienteCardv1";
import { PaymentBonoFormv1 } from "./components/bonov1/PaymentBonoFormv1";

const theme = createTheme({
  typography: {
    body2: {
      color: "rgba(0, 0, 0, 0.6);",
    },
  },
});

function PatientPaymentv1() {
  const queryParams = queryString.parse(window.location.search);

  return (
    <div className="App">
      <PaymentProvider>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MainToolBar />
            <Box
              sx={{
                marginTop: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              {queryParams.id ? (
                <>
                  <PacienteCardv1/>
                  <PaymentBonoFormv1 />
                </>
              ) : (
                <Alert severity="error">La dirección no es correcta!</Alert>
              )}
            </Box>
            <CopyrightBand sx={{ mt: 8, mb: 4 }} />
          </Container>
        </ThemeProvider>
      </PaymentProvider>
    </div>
  );
}

export default PatientPaymentv1;
