import { useReducer } from "react";
import { PaymentReducer } from "../bono/PaymentReducer";
import { IPaymentCuota } from "../../api/api-types/IPaymentCuota";
import React, { useState } from 'react';
import { PaymentCuotaContext } from './PaymentCuotaContext';

const INITIAL_STATE: IPaymentCuota = {};

export const PaymentCuotaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [payment, dispatch] = useReducer(PaymentReducer, INITIAL_STATE);

  const updatePayment = (payment: IPaymentCuota) => {
    dispatch({ type: "updatePayment", payload: payment });
  };
  const [isLoading, setIsLoading] = useState(true);

  return (
    <PaymentCuotaContext.Provider
      value={{
        payment: payment,
        updatePayment: updatePayment,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </PaymentCuotaContext.Provider>
  );
};