import {
  Alert,  
  CssBaseline,  
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import "./App.css";
import {
  createTheme,  
  ThemeProvider,  
} from "@mui/material/styles";
import { PaymentCuotaProvider } from "./context/cuota/PaymentCuotaProvider";
import { CopyrightBand } from "./components/shared/CopyrightBand";
import MainToolBar from "./components/shared/MainToolBar";
import { useParams } from "react-router-dom";
import { ProfesionalCard } from "./components/cuota/ProfesionalCard";
import { PaymentCuotaForm } from "./components/cuota/PaymentCuotaForm";

const theme = createTheme({
  typography: {
    body2: {
      color: "rgba(0, 0, 0, 0.6);",
    },
  },
});

function ProfessionalPayment() {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="App">
      <PaymentCuotaProvider>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MainToolBar />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                width: '100%',
              }}
            >
              {id ? (
                <>
                  <ProfesionalCard payCuotaKey={id} />                   
                  <PaymentCuotaForm />
                </>
              ) : (
                <><Alert severity="error">La dirección no es correcta!</Alert></>
              )}
            </Box>
            <CopyrightBand sx={{ mt: 8, mb: 4 }} />
          </Container>
        </ThemeProvider>
      </PaymentCuotaProvider>
    </div>
  );
}

export default ProfessionalPayment;
