import {
  Box,
  Autocomplete,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { AxiosResponse } from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import { ICreatePaymentRequest } from "../../api/api-types/ICreatePaymentRequest";
import { ICreatePaymentResponse } from "../../api/api-types/ICreatePaymentResponse";
import { PacienteApi } from "../../api/pacienteApi";
import { PaymentContext } from "../../context/bono/PaymentContext";
import { ICurrentMontoResponse } from "../../api/api-types/ICurrentMontoResponse";
import Grid from '@mui/material/Grid2';
import CustomButton from "../cuota/CustomButton";
import CreditCardIcon from '../../assets/credit-card.svg';
import PagoFacilIcon from '../../assets/PagoFacil.svg';

export const PaymentBonoForm = (props: any) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_self");
  };

  // Fetch data from API
  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        var api = new PacienteApi();
        var axiosResp: AxiosResponse<ICurrentMontoResponse | null> = await api.CurrentMontos();
        if (axiosResp.data?.entity) {
          var montos = [
            axiosResp.data.entity.monto1.toString(),
            axiosResp.data.entity.monto2.toString(),
            axiosResp.data.entity.monto3.toString()
          ];
          setOptions(montos);
        }
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const context = useContext(PaymentContext);
  var email = context.payment.paciente?.email;
  var error = context.payment.error;

  const handleInput = (e: React.SyntheticEvent, value: string) => {
    let payment = context.payment;
    if (payment.paciente) {
      payment.paciente.email = value;
    }
    context.updatePayment(payment);
  };

  const handleAutocomplete = (
    e: React.SyntheticEvent,
    value: string | null
  ) => {
    let payment = context.payment;
    if (payment.lastCita && value) {
      payment.lastCita.bono = +value;
    }
    context.updatePayment(payment);
  };

  const apicall = async (type: number) => {

    if (!context.payment.lastCita?.idPaciente || !context.payment.lastCita?.idProfesional) {
      setOpen(true);
      return;
    }
    if (!context.payment.lastCita?.bono || !context.payment.paciente?.email) {
      setOpen(true);
      return;
    }

    let payload: ICreatePaymentRequest = {
      PayCuotaKey: context.payment.lastCita?.idConsultorioNombre,
      IdCalendario: context.payment.lastCita?.id,
      IdPaciente: context.payment.lastCita?.idPaciente,
      IdProfesional: context.payment.lastCita?.idProfesional,
      Monto: context.payment.lastCita?.bono,
      Nombre: context.payment.paciente?.nombre,
      Apellidos: context.payment.paciente?.apellidos,
      Email: context.payment.paciente?.email,
    };

    var api = new PacienteApi();
    var axiosResp: AxiosResponse<ICreatePaymentResponse | null> =
      await api.PagarOnline(payload);

    if (axiosResp.status == 200) {
      if (type == 1 && axiosResp.data && axiosResp.data.checkoutLink) {
        openInNewTab(axiosResp.data.checkoutLink);
      }
      if (type == 2 && axiosResp.data && axiosResp.data.couponLink) {
        openInNewTab(axiosResp.data.couponLink);
      }
    }
  };

  return (
    <>
      {!error &&
        <Card sx={{ marginTop: 3 }} {...props}>
          <Box sx={{ backgroundColor: '#E3F2FD', padding: 1 }}>
            <Typography variant="h6" sx={{ color: '#1976D2', fontSize: '1rem' }}>
              PAGO
            </Typography>
          </Box>
          <CardContent sx={{ paddingBottom: "0!important" }}>
            <Grid container spacing={1.5}>
              <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    Algunos valores no se han completado.
                  </Alert>
                </Snackbar>

                <TextField
                  fullWidth
                  size="small"
                  id="email"
                  type="email"
                  value={email}
                  label="Email"
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                  onChange={(e) => {
                    handleInput(e, e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>

                <Autocomplete
                  fullWidth
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={options}
                  loading={loading}
                  style={{ marginBottom: 15 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Importe"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }} />
                  )}
                  onChange={(event, value) => handleAutocomplete(event, value)}
                />

                <CustomButton mainText="Crear Cupón de Pago" subText="Realizar el pago en Pago Fácil, RapiPago, PagoMisCuentas o RED LINK." logoSrc={PagoFacilIcon} onClick={() => apicall(2)} />
                <CustomButton mainText="Pagar ahora Online" subText="Realizar el pago con tarjeta de Crédito/Débito, DEBIN o QR." logoSrc={CreditCardIcon} onClick={() => apicall(1)} />                
              </Grid>
            </Grid>
          </CardContent>
        </Card>}
    </>
  );
};
