import {
    Alert,    
    Card,
    CardContent,    
    Divider,    
    Grid,    
    Typography,
  } from "@mui/material";
  import  { AxiosResponse } from "axios";
  import {  useEffect,  useState } from "react";  
  import { PacienteApi } from "../../api/pacienteApi"; 
  import { usePayment } from "../../hooks/usePayment";
  import queryString from "query-string";
  import { IPayment } from "../../api/api-types/IPayment";
  
  export const PacienteCardv1 = () => {
    const queryParams = queryString.parse(window.location.search);
  
    const [loading, setLoading] = useState(true);
    const { pac: pay, updatePayment } = usePayment();
  
    const apicall = async () => {
      if (queryParams.id) {
        let query: string = queryParams.id as string;
        var api = new PacienteApi();
        var axiosResp: AxiosResponse<IPayment | null> = await api.getPaciente(
          query
        );
        updatePayment(axiosResp.data ?? {});
      } else {
        updatePayment({
          paciente: {
            nombreApellidos: "",
            telMovil: "",
            tipoDocDescripcion: "",
            nroDoc: "",
            email: "",
            idEstadoDescripcion: "",
            fecAlta: "2022-01-01T00:00:00.000",
          },
          lastCita: {
            idPersonaNombre: "",
            fecDesde: "2022-01-01T08:20:00.000",
            idEstadoDescripcion: "",
            bono: 0,
          },
        });
      }
  
      setLoading(false);
    };
  
    useEffect(() => {
      apicall();
    }, []);
  
    return (
      <>
        {pay.error && <Alert severity="error">{pay.error}</Alert>}
        {!pay.error && (
          <>
            <Card sx={{ marginTop: 3 }}>
              <CardContent sx={{ paddingBottom: "0!important" }}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="h6"
                      component="h2"
                      align="left"
                      gutterBottom
                    >
                      PACIENTE
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body2" component="div" align="left">
                      Nombre
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {pay.paciente?.nombreApellidos}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="body2" component="div" align="left">
                      Teléfono
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {pay.paciente?.telMovil}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="body2" component="div" align="left">
                      Documento
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {pay.paciente?.tipoDocDescripcion}:{pay.paciente?.nroDoc}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm="auto" md="auto">
                    <Typography variant="body2" component="div" align="left">
                      Email
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {pay.paciente?.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="body2" component="div" align="left">
                      Desde el
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {new Date(pay.paciente?.fecAlta ?? "").toLocaleDateString()}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: 3 }}>
              <CardContent sx={{ paddingBottom: "0!important" }}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="h6"
                      component="h2"
                      align="left"
                      gutterBottom
                    >
                      SESSION
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body2" component="div" align="left">
                      Profesional
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {pay.lastCita?.idPersonaNombre}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="body2" component="div" align="left">
                      Fecha
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {new Date(
                        pay.lastCita?.fecDesde ?? ""
                      ).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="body2" component="div" align="left">
                      Hora
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {new Date(pay.lastCita?.fecDesde ?? "").toLocaleTimeString(
                        [],
                        { timeStyle: "short" }
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="body2" component="div" align="left">
                      Estado
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {pay.lastCita?.idEstadoDescripcion}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="body2" component="div" align="left">
                      Bono
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {pay.lastCita?.bono}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        )}
      </>
    );
  };
  