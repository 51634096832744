import { Link, Typography } from "@mui/material";

export const CopyrightBand = (props: any) => {
  return (
    <Typography
      sx={{ margenTop: 2 }}
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.institucionulloa.com.ar/">
        Institución Fernando Ulloa
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};
