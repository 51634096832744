import React from 'react';
import ReactDOM from 'react-dom/client';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './index.css';
import PatientPayment from './PatientPayment';
import ProfessionalPayment from './ProfessionalPayment';
import PatientPaymentv1 from './PatientPaymentv1';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/bono/:id" element={<PatientPayment />} />
        <Route path="/cuota/:id" element={<ProfessionalPayment />} />
        <Route path="/" element={<PatientPaymentv1 />} />
      </Routes>
    </Router>
  </React.StrictMode>
);