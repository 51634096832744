import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Skeleton,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { AxiosResponse } from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import { ICreatePaymentRequest } from "../../api/api-types/ICreatePaymentRequest";
import { ICreatePaymentResponse } from "../../api/api-types/ICreatePaymentResponse";
import { PacienteApi } from "../../api/pacienteApi";
import { PaymentCuotaContext } from "../../context/cuota/PaymentCuotaContext";
import { ICurrentMontoResponse } from "../../api/api-types/ICurrentMontoResponse";
import Grid from '@mui/material/Grid2';
import CustomButton from "./CustomButton";

import CreditCardIcon from '../../assets/credit-card.svg';
import PagoFacilIcon from '../../assets/PagoFacil.svg';
import { ICreatePaymentCuotaRequest } from "../../api/api-types/ICreatePaymentCuotaRequest";

export const PaymentCuotaForm = (props: any) => {
  const { isLoading, payment } = useContext(PaymentCuotaContext);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };  

  const openInNewTab = (url: string) => {
    window.open(url, "_self");
  };

  var saldo: number = payment.payCuota?.saldo as number;
  var error = payment.error;

  const apicall = async (type: number) => {

    let payload: ICreatePaymentCuotaRequest = {
      Key: payment.payCuota?.key,
      Name: payment.profesional?.nombre,
      LastName: payment.profesional?.apellidos,
      Email: payment.profesional?.email,
      Amount: Math.abs(payment.payCuota?.saldo ?? 0)
    };

    var api = new PacienteApi();
    var axiosResp: AxiosResponse<ICreatePaymentResponse | null> =
      await api.PagarCuotaOnline(payload);

    if (axiosResp.status == 200) {
      if (type == 1 && axiosResp.data && axiosResp.data.checkoutLink) {
        openInNewTab(axiosResp.data.checkoutLink);
      }
      if (type == 2 && axiosResp.data && axiosResp.data.couponLink) {
        openInNewTab(axiosResp.data.couponLink);
      }
    }
  };

  return (
    <>
      {!error &&
        <Card sx={{ marginTop: 3 }} {...props}>
          <Box sx={{ backgroundColor: '#E3F2FD', padding: 1 }}>
            <Typography variant="h6" sx={{ color: '#1976D2', fontSize: '1rem' }}>
              PAGO
            </Typography>
          </Box>
          <CardContent sx={{ paddingBottom: "0!important" }}>
            <Grid container spacing={1.5}>
              <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                <Box>
                  {isLoading ?
                    <Skeleton animation="wave" width={"100%"} height={70} /> :
                    <>
                      <Typography variant="subtitle1" sx={{ color: '#1976D2', fontSize: '40px !important', fontWeight: '800px !important', fontFamily: "Poppins,sans-serif" }}>
                        {formatCurrency(Math.abs(saldo))}
                        <span className="currency-code" style={{ marginLeft: '.4em', fontSize: '.5em' }}>ARS</span>
                      </Typography>
                    </>
                  }
                </Box>
                <CustomButton mainText="Crear Cupón de Pago" subText="Realizar el pago en Pago Fácil, RapiPago, PagoMisCuentas o RED LINK." logoSrc={PagoFacilIcon} onClick={() => apicall(2)} />
                <CustomButton mainText="Pagar ahora Online" subText="Realizar el pago con tarjeta de Crédito/Débito, DEBIN o QR." logoSrc={CreditCardIcon} onClick={() => apicall(1)} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>}
    </>
  );
};