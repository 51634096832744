import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface LinedHeaderProps {
  children: React.ReactNode;
  label: string; // Add a prop for the label
}

const LinedHeader: React.FC<LinedHeaderProps> = ({ children, label }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.12)', // Border color
        borderRadius: '8px',                    // Rounded corners
        padding: '12px 5px 10px 5px',                    // Padding inside the box
        position: 'relative',
        color: 'text.secondary',                // Label color
        display: 'flex',
        alignItems: 'center',
        //minWidth: '250px',                      // Minimum width
        backgroundColor: 'transparent',
        height: '100%'
      }}
    >
      <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          top: '-10px',
          left: '12px',
          backgroundColor: theme.palette.background.paper,
          padding: '0 4px',
          fontSize: '12px',
        }}
      >
        {label} 
      </Typography>
      {children}
    </Box>
  );
};

export default LinedHeader;