import { AxiosResponse } from "axios";
import { ApiClient } from "./";
import { ICreatePaymentRequest } from "./api-types/ICreatePaymentRequest";
import { ICreatePaymentResponse } from "./api-types/ICreatePaymentResponse";
import { IRetrieveRequest } from "./api-types/IRetrieveRequest";
import { IPayment } from "./api-types/IPayment";
import { ICurrentMontoResponse } from "./api-types/ICurrentMontoResponse";
import { IPaymentCuota } from "./api-types/IPaymentCuota";
import { ICreatePaymentCuotaRequest } from "./api-types/ICreatePaymentCuotaRequest";

export class PacienteApi extends ApiClient {

  async getPaciente(id: string): Promise<AxiosResponse<IPayment | null>> {
    return await this.requestPost<IPayment, IRetrieveRequest>(`payment/history`, {Id: id});
  }

  async getPacienteByKey(id: string): Promise<AxiosResponse<IPayment | null>> {
    return await this.requestGet<IPayment, IRetrieveRequest>(`payment/paybono/${id}`);
  }

   async getProfesionalByKey(id: string): Promise<AxiosResponse<IPaymentCuota | null>> {
    return await this.requestGet<IPayment, IRetrieveRequest>(`payment/paycuota/${id}`);
  }

  async PagarOnline(payload: ICreatePaymentRequest): Promise<AxiosResponse<ICreatePaymentResponse | null>> {
    return await this.requestPost<ICreatePaymentResponse,ICreatePaymentRequest>(`payment`, payload);
  }

  async PagarCuotaOnline(payload: ICreatePaymentCuotaRequest): Promise<AxiosResponse<ICreatePaymentResponse | null>> {
    return await this.requestPost<ICreatePaymentResponse,ICreatePaymentCuotaRequest>(`payment/paycuota`, payload);
  }

  async CurrentMontos(): Promise<AxiosResponse<ICurrentMontoResponse | null>> {
    return await this.requestGet<ICurrentMontoResponse>(`payment/current-montos`);
  }

}
