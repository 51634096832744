import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useContext, useEffect, useRef, useState } from "react";
import { PacienteApi } from "../../api/pacienteApi";
import { usePaymentCuota } from "../../hooks/usePaymentCuota";
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TransactionTable from "./TransactionTable";
import { PaymentCuotaContext } from "../../context/cuota/PaymentCuotaContext";
import LinedHeader from "./LinedHeader";
import { AxiosError } from "axios";

interface ProfesionalCardProps {
  payCuotaKey: string;
}

export const ProfesionalCard: React.FC<ProfesionalCardProps> = ({ payCuotaKey }) => {
  const { setIsLoading } = useContext(PaymentCuotaContext);
  const [loading, setLoading] = useState(true);
  const { pac: pay, updatePayment } = usePaymentCuota();

  const hasFetched = useRef(false);

  function getMonthName(monthNumber: number, locale = 'es-AR') {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString(locale, { month: 'long' }).toUpperCase();
  }

  const fetchProfesionalData = async () => {
    setIsLoading(true);
    if (payCuotaKey) {
      const api = new PacienteApi();
      try {
        const response = await api.getProfesionalByKey(payCuotaKey);
        if (response.data?.error) {
          updatePayment({ error: response.data.error });
        } else {
          updatePayment(response.data ?? { items: [] });
        }
      } catch (error: any) {
        if (error.response.data) {
          updatePayment({ error: error.response.data });
        } else if (error.status == 404) {
          updatePayment({ error: 'No se pudo encontrar la cuota especificada. Revice que el link que se le envió este correcto.' });
        } else {
          updatePayment({ error: error.message });
        }
      }

    } else {
      updatePayment({
        profesional: {
          nombreApellidos: "",
          telMovil: "",
          tipoDocDescripcion: "",
          nroDoc: "",
          email: "",
          idEstadoDescripcion: "",
          fecAlta: "2022-01-01T00:00:00.000",
        },
        items: [],
        error: "",
      });
    }
    setLoading(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!hasFetched.current) {
      fetchProfesionalData();
      hasFetched.current = true;
    }
  }, [payCuotaKey]);

  return (
    <>
      {pay.error && <Alert severity="error">{pay.error}</Alert>}
      {!pay.error && (
        <>
          <Card sx={{ marginTop: 3 }}>
            <Box sx={{ backgroundColor: '#E3F2FD', padding: 1 }}>
              <Typography variant="h6" sx={{ color: '#1976D2', fontSize: '1rem' }}>
                PROFESIONAL
              </Typography>
            </Box>
            <CardContent>
              <Grid container spacing={0.75}>
                <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                  <Box display="flex" alignItems="center">
                    <PersonIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1" style={{ textTransform: "uppercase" }}>
                        {pay.profesional?.nombreApellidos}
                      </Typography>}
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12 }} >
                  <Box display="flex" alignItems="center">
                    <BadgeIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1">
                        {pay.profesional?.tipoDocDescripcion}:{pay.profesional?.nroDoc}
                      </Typography>
                    }
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12 }} >
                  <Box display="flex" alignItems="center">
                    <PhoneIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1">
                        {pay.profesional?.telMovil}
                      </Typography>
                    }
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                  <Box display="flex" alignItems="center">
                    <EmailIcon sx={{ mr: 2, color: '#1976D2' }} />
                    {loading ? <Skeleton animation="wave" width={"100%"} height={28} /> :
                      <Typography variant="subtitle1">
                        {pay.profesional?.email}
                      </Typography>
                    }
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginTop: 3 }}>
            <Box sx={{ backgroundColor: '#E3F2FD', padding: 1 }}>
              <Typography variant="h6" sx={{ color: '#1976D2', fontSize: '1rem' }}>
                ESTADO DE CUENTA
              </Typography>
            </Box>
            <CardContent>
              <Grid container spacing={1} alignItems="center">
                <Grid size="grow">
                  <LinedHeader label="Período">
                    <Box display="flex" alignItems="left" flexDirection={"column"}>
                      {loading ? <Skeleton animation="wave" width={"100%"} height={70} /> :
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'left',
                              justifyContent: 'left',
                              gap: 1
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                color: '#1976d2',
                                fontWeight: 500,
                                display: 'flex',
                                alignItems: 'left',
                                gap: 1,
                              }}
                            ><CalendarMonthIcon sx={{ color: '#1976d2', fontSize: 28 }} />
                              {pay.payCuota!.mesProceso ? getMonthName(pay.payCuota!.mesProceso) : ''}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'left',
                              justifyContent: 'left',
                              gap: 1
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                color: '#1976d2',
                                fontWeight: 500,
                                display: 'flex',
                                alignItems: 'left',
                                gap: 1,
                              }}
                            > <EventNoteIcon sx={{ color: '#1976d2', fontSize: 28 }} />
                              {pay.payCuota!.anoProceso}
                            </Typography>
                          </Box>
                        </>
                      }
                    </Box>
                  </LinedHeader>
                </Grid>
              </Grid>
              <TransactionTable />
            </CardContent>
          </Card>
        </>
      )
      }
    </>
  );
};
