import React, { useContext, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Typography,
  Tooltip,
  Zoom,
  Skeleton,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SchoolIcon from '@mui/icons-material/School';
import { PaymentCuotaContext } from '../../context/cuota/PaymentCuotaContext';
import TransactionListSkeleton from './TransactionListSkeleton';

const TransactionHistory: React.FC = () => {

  const { isLoading, payment } = useContext(PaymentCuotaContext);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  function getBgColor(transaction: { Type: string; Description: string }): string {
    if (transaction.Type === "G") {
      return '#a82d74';
    }

    if (transaction.Type === "C") {
      return generateColorFromDescription(transaction.Description);
    }

    return 'primary.main';
  }

  function generateColorFromDescription(description: string): string {
    const colors = [
      '#8B0000', // Dark Red
      '#006400', // Dark Green
      '#00008B', // Dark Blue
      '#8B008B', // Dark Magenta
      '#8B4513', // SaddleBrown
      '#2F4F4F', // Dark Slate Gray
      '#4B0082', // Indigo
      '#556B2F', // Dark Olive Green
      '#8B3A3A', // Indian Red
      '#3B3B98', // Dark Slate Blue
      '#3D3C4E', // Gunmetal
      '#1E3F66'  // Dark Midnight Blue
    ];

    // Generate a hash from the full description
    let hash = 0;
    for (let i = 0; i < description.length; i++) {
      hash = description.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Map the hash to a color index
    const index = Math.abs(hash) % colors.length;
    return colors[index];
  }


  return (
    <Box>
      <List>
        {isLoading ? (
          <TransactionListSkeleton />
        ) : (
          <>
            {payment.items!.map((group) => (
              <React.Fragment key={group.Date}>
                <Typography
                  component="div" // Changed to render as a div
                  variant="subtitle2"
                  sx={{
                    mt: 2,
                    mb: 1,
                    pt: 1,
                    pb: 1,
                    pl: 0.5,
                    fontWeight: 'bold',
                    color: 'text.secondary',
                    textAlign: 'left',
                    background: '#343a4005'
                  }}
                >
                  {group.DateOfWeek}
                </Typography>
                {group.Transactions.map((transaction) => (
                  <ListItem key={transaction.Id} divider style={{ padding: 0 }}>
                    <Box
                      sx={{ position: 'relative', mr: 2 }}
                      aria-label={transaction.Confirmed ? 'Verified transaction' : 'Unverified transaction'}
                    >
                      <Avatar sx={{ bgcolor: getBgColor(transaction) }}>
                        {transaction.Type === 'G' ? <SchoolIcon /> : transaction.Description[0]}
                      </Avatar>
                      {transaction.Confirmed && transaction.Type === 'C' && (
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: -4,
                            right: -4,
                            display: 'flex',
                            gap: '2px'
                          }}
                        >
                          <Tooltip title="Confirmado" TransitionComponent={Zoom} arrow>
                            <CheckCircleIcon
                              sx={{
                                fontSize: 18,
                                color: '#4CAF50',
                                backgroundColor: '#fff',
                                borderRadius: '50%',
                                padding: '0.5px'
                              }}
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                    <ListItemText
                      sx={{ maxWidth: '50%' }}
                      primary={transaction.Description}
                      secondary={
                        <span style={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                          <AccessTimeIcon sx={{ fontSize: 16, mr: 0.5 }} />
                          {transaction.CitaTime}
                        </span>
                      }
                    />
                    <ListItemText
                      primary={`${transaction.Type === 'C' ? '+' : '-'}${formatCurrency(transaction.Amount)}`}
                      secondary={`${formatCurrency(transaction.Balance)}`}
                      sx={{
                        textAlign: 'right',
                        '& .MuiTypography-root:first-of-type': {
                          fontWeight: 500,
                          color: transaction.Amount > 0 ? 'success.main' : 'error.main'
                        },
                        '& p.MuiTypography-root:last-of-type': {
                          fontSize: '0.85rem',
                          color: 'text.secondary'
                        },
                        '& span.MuiTypography-root:last-of-type': {
                          fontSize: '1rem',
                          color: 'text.primary'
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </>
        )}
      </List>
    </Box>
  );
};

export default TransactionHistory;