import { useContext } from 'react';
import { PaymentContext } from '../context/bono/PaymentContext';

export const usePayment = () => {

    const { payment: paciente, updatePayment: updatePayment } = useContext( PaymentContext );
    // const { todos } = paciente;

    return {
        pac : paciente,
        // pendingTodos: todos.filter( todo => !todo.completed ).length,
        updatePayment: updatePayment
    }
}