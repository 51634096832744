import React from 'react';
import { Button, Box, Typography } from '@mui/material';


interface CustomButtonProps {
  mainText?: string;
  subText?: string;
  logoSrc?: string;
  onClick?: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  mainText = "Pagar con Pago Fácil",
  subText = "Paga de manera rápida y segura",
  logoSrc = "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-a77JXFNVpijl4kYStVSI4CPwRd391A.png",
  onClick = () => console.log("Button clicked!"),
}) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 0, mb: 2 }}
      onClick={onClick}
    >
      <Box sx={{ position: 'relative', width: 48, height: 48, marginRight: 2, flexShrink: 0 }}>
        <img
          src={logoSrc}
          alt="Pago Facil Logo"
          width={48}
          height={48}
          style={{
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box sx={{ textAlign: 'left', flex: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', lineHeight: 1.2, color: '#ffffff' }}>
          {mainText}
        </Typography>
        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: 10 }}>
          {subText}
        </Typography>
      </Box>
    </Button>
  );
};

export default CustomButton;